import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ProductSetCarousel.module.scss';
import { urlEscape } from '../../utils/helpers';
import classNames from 'classnames';
const LeftArrow = ({ imagePath, onClick, disabled }) => {
  return (
    <button 
      className={classNames('product-set-carousel-previous',styles.prev)} 
      onClick={onClick} 
      disabled={disabled}
    > 
       <img
         src={urlEscape(imagePath)}
         className={styles.navigationIcon}
         alt="left arrow"
       />
    </button>
  );
};

LeftArrow.propTypes = {
  onClick: PropTypes.func,
  imagePath: PropTypes.string,
  disabled:PropTypes.bool,
};

export default LeftArrow;
