import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ProductSetCarousel.module.scss';
import { urlEscape } from '../../utils/helpers';
import classNames from 'classnames';

const RightArrow = ({ imagePath, onClick, disabled, apiLoading }) => {

  return (
    <button 
      className={classNames('product-set-carousel-next',styles.next)} 
      onClick={onClick} 
      disabled={disabled === true || apiLoading === true}
    > 
      {apiLoading === true ?  
        <div className={styles.loader} /> : (
      <img
        src={urlEscape(imagePath)}
        className={styles.navigationIcon}
        alt="Right arrow"
      />
    )}
    </button>
  );
};

RightArrow.propTypes = {
  onClick: PropTypes.func,
  imagePath: PropTypes.string,
  disabled:PropTypes.bool,
  apiLoading:PropTypes.bool,
};

export default RightArrow;
